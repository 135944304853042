import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Constant } from '../blog/constant';
@Injectable({ providedIn: 'any' })
export class  DashboardService {
    constructor(
        public http: HttpClient) {
    }
    getIssuers(body: any) {
        return  this.http.post(environment.urlBackEnd + Constant.api_common_issuer, body);
    }
    getProducts(body: any) {
        return  this.http.post(environment.urlBackEnd + Constant.api_common_products_filter, body);
    }
    getDetailPortfolio(productId: any) {
        return  this.http.get(environment.urlBackEnd + Constant.api_common_issuer + "/" + productId);
    }
    getProductsHomeFilter(body: any) {
        return  this.http.post(environment.urlBackEnd + Constant.api_common_products_home_filter, body);
    }
    following(body: any) {
        return  this.http.post(environment.urlBackEnd + Constant.api_common_following, body);
    }
    getSupport() {
        return  this.http.get(environment.urlBackEnd + Constant.api_user_support_information);
    }
    getProductById(id: any) {
        return this.http.get(environment.urlBackEnd + Constant.api_common_products_id + id);
    }
    postRequest(url: any, body: any) {
        return this.http.post(environment.urlBackEnd +   url, body);
    }
    getCategories() {
        return this.http.get(environment.urlBackEnd + '/blog/categories');
    }
    calculateInvest(body: any) {
        return  this.http.post(environment.urlBackEnd + Constant.api_common_calcualte_invest, body);
    }
    getDataChart(body: any) {
        return this.http.post(environment.urlBackEnd  + Constant.api_get_data_chart, body);
    }
    getProductByCode(code: any) {
        return this.http.get(environment.urlBackEnd + Constant.api_get_product_code + code);
    }
    getPartnerByPath(code: any) {
        return this.http.get(environment.urlBackEnd + Constant.api_get_partner_path + code);
    }
    getPartnerList() {
        return this.http.get(environment.urlBackEnd + Constant.api_get_partner_list);
    }
    getBankInterest() {
        return  this.http.get(environment.urlBackEnd + Constant.api_get_data_bank_interest);
    }
    getImagesForSocial() {
        return  this.http.get(environment.urlBackEnd + '/res/blog/all');
    }
}
